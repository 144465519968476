<template>
	<template-base class="containerCard">
		<div>
			<div class="container-head">
				<div
					v-if="possuiPermissao('GER_I_GRUPO_USUARIO')"
					class="container-add-new"
					v-bind:class="!groupsUsers.length ? 'container-add-new-100' : ''"
				>
					<b-button class="add-button" @click="mostrarEdicao({})">
						Adicionar
					</b-button>
				</div>
			</div>
			<div v-if="groupsUsers.length" class="head-row mb-2 width-pagination-items">
				<div class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + groupsUsers.length }} de {{ pagination.count }}
					</label>
					<b-pagination
						class="mr-0 mb-2"
						id="page"
						v-model="currentPage"
						:total-rows="pagination.count"
						:per-page="pagination.limit.value"
					></b-pagination>
					</div>
				<b-form
					class="text-head-row width-pagination">
					<label class="text-head-row ml-0">
						Itens por página
					</label>
					<b-form-select
						class="mr-0 mb-2"
						v-model="pagination.limit.value"
						name="itens-por-pagina"
						:options="optionsItensPorPag"
						@input="changeItensByPage()"
						:disabled="loading"
					/>
				</b-form>
			</div>
			<b-modal
				ref="modalEdicao"
				hide-footer
				:title="titleModalEdicao"
				size="lg"
			>
				<div class="modal-edicao d-flex mb-3 modal-edicao-label">
					<b-col cols="12" class="d-block text-parcela">
						<span class="label-conta">Nome do Grupo de Usuários</span>
						<b-form-input
							name="nome_grupo"
							class="modal-edicao-input"
							type="text"
							v-model="grupoUsuarioSelecionado.name"
							autocomplete="off"
							placeholder="Digite o nome do grupo de usuários"
							v-validate="{ required: true }"
							:state="validateState('nome_grupo')"
						/>
					</b-col>
				</div>
				<div class="modal-edicao d-flex modal-edicao-label">
					<b-col cols="12" class="d-block mb-5 text-parcela">
						<span class="label-conta">Filtrar Funcionalidades</span>
						<b-form-input
							name="funcionalidade"
							class="modal-edicao-input"
							type="text"
							placeholder="Digite aqui para filtrar as funcionalidades"
							@input="(v) => filtraFuncionalidades(v)"
						/>
					</b-col>
				</div>
				<div class="table-responsive table-funcionalidades">
					<table class="table table-hover condensed text-parcela" data-cy="Tabela" :data-asc="true">
						<thead class="label-contaa">
							<tr>
								<th>Habilitada</th>
								<th>Funcionalidades</th>
							</tr>
						</thead>
						<tbody class="table-td-funcionalidade">
							<tr v-for="(item, idx) in grupoUsuarioSelecionado.funcionalidades" :key="idx">
								<td v-if="item.visible">
									<ToggleSwitch
										:name="`funcionalidade-${item.idFuncionality}`"
										:ref="`funcionalidade-${item.idFuncionality}`"
										:value="item.habilitada"
										@toggle="_ => changeFuncionalidade(idx)"
									/>
								</td>
								<td v-if="item.visible">
									{{ item.name }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalEdicao')">Cancelar</b-button>
				<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
				<b-button
					class="mt-3 ml-1 float-right btn-modal btn-salvar"
					@click="salvarEdicao"
					:disabled="invalidForm"
				>
					Salvar
				</b-button>
			</b-modal>
		</div>
		<div v-if="groupsUsers.length" class="table-responsive">
			<DataTable v-if="!isMobile"
				class="data-table"
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:nosearch="true"
				:nofilters="true"
				name="gruposUsuarios"
				:hasPagination="true"
				:noedit="true"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_GRUPO_USUARIO')"
				:permissaoDelete="possuiPermissao('GER_D_GRUPO_USUARIO')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				@clickSortTableBy="(v) => sortTableBy(v)"
				:state="{
					sortBy: sortTable.sortBy,
					sortAsc: sortTable.order === 1,
					query: ''
				}"
				:async="true"
			/>
			<CardTransaction v-else
				:loading="loading"
				:colunas="colunas"
				:linhas="linhas"
				:errMsg="errMsg"
				:acoes="true"
				:permissaoEdit="possuiPermissao('GER_U_GRUPO_USUARIO')"
				:permissaoDelete="possuiPermissao('GER_D_GRUPO_USUARIO')"
				@clickDelete="confirmaApagar"
				@clickEdit="mostrarEdicao"
				:selectItem="false"
				:globalAcoes="false"
				@clickCheck="false"
				:colsDataCard="colsDataCard"
				:details="false"
				:showSelectItem="false"
			/>
		</div>
		<div v-else class="alert alert-warning" role="alert">
			{{ errMsg }}
		</div>
	</template-base>
</template>

<script>
	import ToggleSwitch from "@/components/ToggleSwitch";

	import DataTable from "@/components/DataTable";
	import TemplateBase from "@/templates/Base";

	import { possuiPermissao } from "../../helpers/permissions";
	import { FuncionalityService } from "../../services/funcionalities";
	import { GroupsUsersService } from "../../services/groupsUsers";
	import { hasFieldsWithErrors } from "@/helpers/validators";
	import CardTransaction from "@/components/CardTransaction.vue";


	export default {
		components: {
			TemplateBase,
			ToggleSwitch,
			DataTable,
			CardTransaction
		},

		inject: ["parentValidator"],

		data () {
			return {
				groupsUsers: [],
				linhas: [],
				titleModalEdicao: "",
				loading: true,
				funcionalidades: [],
				grupoUsuarioSelecionado: {
					idGroupUser: "",
					name: "",
					funcionalidades: []
				},

				invalidForm: true,
				groupsUsersService: new GroupsUsersService(),
				funcionalityService: new FuncionalityService(),

				errMsg: "Nenhum grupo de usuários cadastrado!",
				colunas: [
					"Grupo de Usuários"
				],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],
				possuiPermissao,
				sortTable: {
					sortBy: 0,
					order: -1
				},
				isMobile: false,
				colsDataCard: {
					0: {
						idx: 0,
						label: "Grupo de Usuários",
						textAlign: "left",
						cols: 12,
						visible: true
					},
					1: {
						idx: null,
						label: "",
						textAlign: "center",
						cols: 4,
						visible: false
					},
					2: {
						idx: null,
						label: "",
						textAlign: "left",
						cols: 4,
						visible: false
					},
					3: {
						idx: null,
						label: "",
						textAlign: "left",
						cols: 6,
						visible: false
					},
					4: {
						idx: null,
						label: "",
						textAlign: "center",
						cols: 2,
						visible: false
					},
					5: {
						idx: null,
						label: "",
						textAlign: "",
						cols: 0,
						visible: false
					},
					6: {
						idx: null,
						label: "",
						textAlign: "center",
						cols: 6,
						visible: false
					},
					7: {
						idx: null,
						label: "",
						textAlign: "center",
						cols: 6,
						visible: false
					}
				}
			};
		},

		watch: {
			currentPage () {
				this.changePage(this.currentPage);
			}
		},

		created () {
			this.$validator = this.parentValidator;
		},

		async mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);

			this.findGroupsUsers();
			this.findFuncionalidades();
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 768;
			},

			sortTableBy (v) {
				this.sortTable = v;
				this.findGroupsUsers();
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findGroupsUsers();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findGroupsUsers();
			},

			async findGroupsUsers () {
				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;

				const result = await this.groupsUsersService.listGroupsUsers({offset, limit, sortTable}) || {};
				this.groupsUsers = result.rows || [];
				this.linhas = result.rows.reduce((acc, gu) => [
					...acc,
					{
						idGroupUser: gu.idGroupUser,
						cols: [gu.name]
					}
				], []);

				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
			},

			async findFuncionalidades () {
				this.funcionalidades = await this.funcionalityService.listFuncionalities() || [];
				this.funcionalidades = this.funcionalidades.map((f) => ({ ...f, habilitada: false, visible: true }));
			},

			mostrarEdicao (item_) {
				let item = {};
				if (item_.idGroupUser)
					[ item ] = this.groupsUsers.filter(gu => gu.idGroupUser === item_.idGroupUser);

				const { idGroupUser, name } = item;

				if (!idGroupUser)
					this.titleModalEdicao = "Cadastrar Novo Grupo de Usuários";
				else
					this.titleModalEdicao = "Editar Grupo de Usuários";

				this.groupsUsers.forEach(gu => {
					gu.funcionalities.forEach(f => f.habilitada = true);
				});

				this.invalidForm = true;
				this.grupoUsuarioSelecionado.idGroupUser = idGroupUser || "";
				this.grupoUsuarioSelecionado.name = name || "";
				this.grupoUsuarioSelecionado.funcionalidades = this.funcionalidades.map(f => {
					f.habilitada = item.funcionalities ? item.funcionalities.some(it => it.idFuncionality === f.idFuncionality) : false;
					f.visible = true;
					return f;
				});
				this.$refs.modalEdicao.show();
			},

			async confirmaApagar (item_) {
				const [ item ] = this.groupsUsers.filter((gu) => gu.idGroupUser === item_.idGroupUser);
				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja remover o grupo de usuário?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.idGroupUser);
			},

			async salvarEdicao () {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				const payload = {
					idGroupUser: this.grupoUsuarioSelecionado.idGroupUser,
					name: this.grupoUsuarioSelecionado.name,
					funcionalities: this.grupoUsuarioSelecionado.funcionalidades
				};

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						await this.groupsUsersService.upsertGroupUser({payload});

						this.fecharModal("modalEdicao");
						this.findGroupsUsers();

						return {
							title: "Sucesso!",
							body: "Grupo de usuários salvo com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar o grupo de usuários!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
			},

			async apagar (idGroupUser) {
				const config = {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				};

				this.$snotify.async("Aguarde...", "Apagando", async () => {
					try {
						await this.groupsUsersService.deleteGroupUser({idGroupUser});
						this.findGroupsUsers();

						return {
							title: "Sucesso!",
							body: "Grupo de usuários deletado com sucesso.",
							config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar deletar o grupo de usuários!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			limparModal () {
				this.grupoUsuarioSelecionado.name = "";
				this.grupoUsuarioSelecionado.funcionalidades.forEach(f => f.habilitada = false);
			},

			changeFuncionalidade (idx) {
				const item = this.grupoUsuarioSelecionado.funcionalidades[idx];
				item.habilitada = !item.habilitada;
				this.invalidForm = !this.grupoUsuarioSelecionado.name;
			},

			filtraFuncionalidades (v) {
				const stringLength = v.length;
				this.grupoUsuarioSelecionado.funcionalidades.forEach((guf) => {
					const string = guf.name.substr(0, stringLength);
					if (stringLength > 0 && string.toLowerCase() !== v.toLowerCase())
						guf.visible = false;
					else
						guf.visible = true;

					return;
				});
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			}
		}
	};
</script>

<style scoped>
	.table-funcionalidades {
		max-height: 40vh;
		overflow-y: scroll;
	}

	.text-funcionalidade {
		font-family: 'Roboto Condensed Regular';
		font-size: 20px;
	}

	.label-funcionalidade {
		color: #696969 !important;
	}

	.table-th-funcionalidade {
		font-family: 'Roboto Condensed Medium';
		font-size: 20px;
	}

	.table-td-funcionalidade {
		font-family: 'Roboto Condensed Regular';
		font-size: 16px;
	}

	.table-td-funcionalidade td {
		vertical-align: middle;
	}

	label {
		width: 160px;
	}

	.container-head {
		display: flex;
		height: 5rem;
		width: 100%;
	}

	.container-pages {
		display: flex;
		width: 70%;
		margin-top: 1.5rem;
	}

	.containerCard {
		max-width: 90.5%;
	}

	.modal-edicao-label {
		font-family: 'Roboto Condensed Regular';
		font-size: 23px;
	}

	.modal-edicao-input {
		font-family: 'Roboto Condensed Regular';
		font-size: 20px;
	}

	.table-body-detalhe {
		padding: 5px 0;
	}

	.container-add-new-100 {
		width: 100% !important;
	}
	@import '../../assets/css/custom-style.css'
</style>
